import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Section, Button } from '../components/common';
import {
  Header,
  Faq,
  ProductGallery,
  CheckoutDraw
} from '../components/layout/index.js';
import styled from 'styled-components';
import { StyledRadio } from '../components/layout/FormStyles';
import media from '../components/layout/Media';
import { StripeProvider } from 'react-stripe-elements';
import formatMoney from '../lib/formatMoney';

const ProductDetails = styled.div`
  margin-top: 1.5rem;
`;
const ProductBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;

  .attn {
    background: #f6dadb;
    padding: 1rem;
    margin-bottom: 1rem;
  }
`;
const ProductCol = styled.div`
  @media ${media.mobileL} {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 45%;
    padding: 0 2rem;
  }
`;

export default class productPage extends React.Component {
  state = {
    disabled: false,
    buttonText: 'BUY NOW',

    amount: 0,
    currency: '',
    varient: '',
    stripe: null,
    showDraw: false,
    title: ''
  };

  toggleDraw = event => {
    event.preventDefault();
    this.setState({
      showDraw: !this.state.showDraw
    });

    const price = formatMoney(this.state.amount, true, true);

    dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: this.state.currency,
        add: {
          // 'add' actionFieldObject measures.
          products: [
            {
              //  adding a product to a shopping cart.
              name: this.state.title + ' - ' + this.state.varient,
              id: '12345',
              price: price,
              brand: '',
              category: '',
              variant: this.state.varient,
              quantity: 1
            }
          ]
        }
      }
    });
  };

  resetButton() {
    this.setState({ disabled: false, buttonText: 'BUY NOW' });
  }

  updateAmount = (currency, varient) => e => {
    this.setState({ amount: e.target.value, currency, varient });
  };

  componentDidMount() {
    if (this.props.data.datoCmsProduct.pricing[0] !== undefined) {
      this.setState({
        amount: this.props.data.datoCmsProduct.pricing[0].price,
        currency: this.props.data.datoCmsProduct.pricing[0].currency,
        varient: this.props.data.datoCmsProduct.pricing[0].name,
        stripe: window.Stripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY),
        title: this.props.data.datoCmsProduct.title
      });
    }
  }

  render() {
    const { data } = this.props;
    const {
      title,
      intro,
      additionalInfo,
      terms,
      featuredImage,
      pricing,
      subtitle,
      productImage,
      hasProductImage,
      menuDark
    } = data.datoCmsProduct;

    return (
      <StripeProvider stripe={this.state.stripe}>
        <>
          <HelmetDatoCms seo={data.datoCmsProduct.seoMetaTags} />

          <Header
            backgroundImage={featuredImage.fluid}
            poster={featuredImage.url}
            pageTitle={title}
            tagline={subtitle}
            propertyName={title}
            caption={featuredImage.title}
            alt={featuredImage.alt}
            darken="true"
            menuDark={menuDark}
          />
          <CheckoutDraw
            open={this.state.showDraw}
            data={this.state}
            toggleDraw={this.toggleDraw}
          />

          <Section>
            <ProductBody>
              <ProductCol>
                <ProductDetails>
                  <div dangerouslySetInnerHTML={{ __html: intro }} />
                </ProductDetails>
                <ProductGallery
                  productImage={productImage}
                  hasProductImage={hasProductImage}
                />

                <ProductDetails>
                  <Faq
                    question="Additional info &#x25bc;"
                    answer={additionalInfo}
                  />
                  <Faq
                    question="Terms and conditions &#x25bc;"
                    answer={terms}
                  />
                </ProductDetails>
              </ProductCol>
              <ProductCol>
                <div style={{ position: 'sticky', top: 0, zIndex: 3 }}>
                  {this.props.data.datoCmsProduct.pricing[0] === undefined ? (
                    <p>Sorry this product can't be booked online right now</p>
                  ) : (
                    <>
                      <h2>Let's book this thing!</h2>

                      <form onSubmit={event => this.toggleDraw(event)}>
                        <StyledRadio>
                          {pricing.map((block, index) => (
                            <div className="inputGroup" key={block.id}>
                              {index === 0 ? (
                                <input
                                  type="radio"
                                  value={
                                    block.promotionalPrice
                                      ? block.promotionalPrice
                                      : block.price
                                  }
                                  onClick={this.updateAmount(
                                    block.currency,
                                    block.name
                                  )}
                                  name="selectVariant"
                                  id={block.id}
                                  defaultChecked
                                />
                              ) : (
                                <input
                                  type="radio"
                                  value={
                                    block.promotionalPrice
                                      ? block.promotionalPrice
                                      : block.price
                                  }
                                  onClick={this.updateAmount(
                                    block.currency,
                                    block.name
                                  )}
                                  name="selectVariant"
                                  id={block.id}
                                />
                              )}

                              <label
                                htmlFor={block.id}
                                style={
                                  block.promotionalPrice && {
                                    color: '#c61a1a'
                                  }
                                }
                              >
                                {block.name}
                                <span>
                                  {' '}
                                  -{' '}
                                  {block.promotionalPrice ? (
                                    <span>
                                      SALE <del>{formatMoney(block.price)}</del>{' '}
                                      {block.currency.toUpperCase()}
                                      {formatMoney(block.promotionalPrice)}{' '}
                                    </span>
                                  ) : (
                                    <span>
                                      {block.currency.toUpperCase()}
                                      {formatMoney(block.price)}
                                    </span>
                                  )}
                                </span>
                              </label>
                            </div>
                          ))}
                        </StyledRadio>
                        <Button
                          primary
                          type="submit"
                          disabled={this.state.disabled}
                        >
                          {this.state.buttonText}
                        </Button>
                      </form>
                    </>
                  )}

                  {this.state.paymentMessage}
                </div>
              </ProductCol>
            </ProductBody>
          </Section>
        </>
      </StripeProvider>
    );
  }
}

export const query = graphql`
  query ProductPageQuery($slug: String!) {
    datoCmsProduct(slug: { eq: $slug }) {
      title
      intro
      subtitle
      menuDark
      additionalInfo
      terms
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      pricing {
        ... on DatoCmsVarient {
          model {
            apiKey
          }
          id
          name
          price
          currency
          promotionalPrice
        }
      }
      hasProductImage
      productImage {
        url
        alt
        title
        fluid(
          maxWidth: 2000
          imgixParams: { fm: "jpg", auto: "format", q: 40 }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      featuredImage {
        url
        alt
        title
        fluid(
          maxWidth: 2000
          imgixParams: {
            fm: "jpg"
            fit: "crop"
            crop: "faces"
            auto: "format"
            q: 40
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
